import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import axios from '../../api/axios';
// import Moment from 'moment';

export default function Lane() {

    const [data, getData] = useState([])

    useEffect(() => {
        let local_data = [
		{event_id: 1, type: 'Life', glyphicon: 'glyphicon-heart', title: 'The journey begins', text: 'Born on 23rd November 1942 to Nadella Sampoornavati & Siva Lingaiah Chowdary, along side the sound of slogan \'Quit India\', in the beautiful village Puritigadda, Andhra Pradesh. Enjoy the music from the year he was born.', link: 'https://www.youtube.com/watch?v=mmuC2rRvg_k'},
		{event_id: 3, type: 'Life', glyphicon: 'glyphicon-book', title: 'Education', text: 'Graduated high school from SR High School, Challap…patnam and graduated with a B.Sc. Degree in 1962.' },
		{event_id: 4, type: 'Work', glyphicon: 'glyphicon-flag', title: 'Job', text: 'Came to Bhagyanagaram, to join South Central Railways on 1964 August 20, Secunderabad'},
		{event_id: 5, type: 'Life', glyphicon: 'glyphicon-heart', title: 'Marriage', text: 'He got married in 1968 to Kusuma Lakshmi (Daughter of Smt. Bhanumati & Late Sri. Pardhasarathi, Rayagada)'},
		{event_id: 6, type: 'Family', title: 'First Child', glyphicon: 'glyphicon-heart', text: 'Nalini (Baby), was born in 1969.'},
		{event_id: 7, type: 'Family', glyphicon: 'glyphicon-heart', title: 'Second Child', text: 'Sambasiva Rao (Babu) was born in 1971'},
		{event_id: 8, type: 'Family', glyphicon: 'glyphicon-heart', title: 'Third Child', text: 'Srinivasa Rao Chowdary (Chinna), was born in 1975'},
		{event_id: 9, type: 'Life', glyphicon: 'glyphicon-home', title: 'Home', text: '1981 they entered their humble adobe where they are currently enjoying their golden years.'},
		{event_id: 10, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Venkateswara Rao', text: 'Son-in-Law joined the family 1992'},
		{event_id: 11, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Vineela', text: 'First Granddaughter born 1993'},
		{event_id: 12, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Sowmya', text: 'Second Granddaughter born 1997'},
		{event_id: 13, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Kavitha', text: 'First Daughter-in-Law joins the family 1998'},
		{event_id: 14, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Praneet', text: 'First Grandson born 2002'},
		{event_id: 15, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Archana', text: 'Second Daughter-in-Law joins the family 2004'},
		{event_id: 16, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Samitha', text: 'Third Granddaughter born 2005'},
		{event_id: 17, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Sriniketh', text: 'Second Grandson born 2006'},
		{event_id: 19, type: 'Work', glyphicon: 'glyphicon-heart', title: 'Sritha', text: 'Youngest Granddaughter born 2011'},
		{event_id: 20, type: 'Work', glyphicon: 'glyphicon-picture', title: 'Job', text: 'Retired from Government Service in 2002.'},
		{event_id: 21, type: 'Work', glyphicon: 'glyphicon-picture', title: 'Favorite Quote', text: 'Man is a Social Animal - Aristotle'},
		{event_id: 22, type: 'Work', glyphicon: 'glyphicon-picture', title: 'Favorite Food', text: 'Chikkudukaya Fry'},
		{event_id: 23, type: 'Work', glyphicon: 'glyphicon-picture', title: 'Favorite Songs', text: 'Kothi Bavaku Pellanta - Malliswari(1951), Mana Suna Manasai - Dr.Chakravarthy (1964), Ghantasala Pushpavilapam,.... and many more'},
		{event_id: 24, type: 'Work', glyphicon: 'glyphicon-picture', title: 'Favorite Thing to Do', text: 'Spending time with Friends and Family, travelling watchin Cricket, and his newly discovered love of Carroms.'},
	];
	// console.log(local_data);
	getData(local_data);
    }, []);

    return (
        <div className="jumbotron text-center form-inline guestView" align="center" >
            <div className="container">
                {data.map((item, i) => (
                    <div key={item.event_id} className="jumbotron text-center form-inline guestView">
			<ul className="timeline">
				<li>
					<div className="timeline-badge" ng-class={item.glyphicon}></div>
					<div className="timeline-panel">
						<p className="timeline-title">{item.title}</p>
						<p>{item.text}</p>
						{/* <p><a href={item.link}>a</a></p> */}
					</div>
				</li>
			</ul>
                    </div>
                ))}
            </div>
            <Outlet />
        </div>
    );
}