import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import "./assets/css/style.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation, Home, Contact, Header, Footer} from "./pages";
import { Album, ViewComments, GiveWishes, Lane } from "./components";

ReactDOM.render(
  <Router>
    <Header />
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/album" element={<Album />} />
      <Route path="/give" element={<GiveWishes />} />
      <Route path="/thoughts" element={<ViewComments />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/lane" element={<Lane />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
