import React from "react";

function Footer() {
  return (
      <footer align="center" className="container">
        <div>
            <a href="https://diamond.vamanaraogaru.com/" target="_blank" rel="noreferrer"><b>Diamond Jubliee</b></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://golden.vamanaraogaru.com/" target="_blank" rel="noreferrer"><b>Golden Jubliee</b></a>
        </div>
        <p className="m-0 text-center text-black">
          Copyright &copy; Sritha
        </p>
      </footer>
  );
}

export default Footer;
