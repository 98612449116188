import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <div className="navigation container-fluid">
      <NavLink className="my-nav" to="/"><h1>Nadella Vamanarao Garu</h1></NavLink>
    </div>
  );
}

export default Navigation;
